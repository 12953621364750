import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 寻人启示列表
const list = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/peopleSearch/list', {params})
}

// 寻人启示统计
const total = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/peopleSearch/total', {params})
}

// 寻人启示详情
const detail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/peopleSearch/detail', {params})
}



export default {
	list,
	total,
	detail
}
