<template>
	<div class="NoticeDetail">
		<w-navTab titleText="寻人启事详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="NoticeDetail-content" id="NoticeDetail">
			<div class="header">
				<div class="header-top">
					<div class="header-top-left">
						{{title}}
					</div>
					<div class="header-top-right">
						{{title}}寻（{{detail.serial_year}}）第 {{detail.serial_number}} 号</span>
					</div>
				</div>
				<div class="header-content">
					<div class="header-content-left" ref="divUrl">
						<img id="urlImg" :src="detail.person_thumb" alt="">
					</div>
					<div class="header-content-right">
						<div class="header-content-text">
							<span>姓名：</span>{{detail.person_name}}
						</div>
						<div class="header-content-text">
							<span>性别：</span>{{detail.person_sex == 1 ? '男':'女'}}
						</div>
						<div class="header-content-text">
							<span>年龄：</span>{{detail.person_age}}岁
						</div>
						<div class="header-content-text">
							<span>身高：</span>{{detail.person_height}}cm
						</div>
						<div class="header-content-text">
							<span>体重：</span>{{detail.person_weight}}kg
						</div>
						<div class="header-content-text">
							<span>走失地址：</span>{{detail.lose_address}}
						</div>
						<div class="header-content-icon" v-if="detail.is_police == 1">
							<img src="../../../src/assets/img/icon608.png" alt="">
						</div>
						<div class="header-content-icon bottom-icon" v-if="detail.is_verify == 1">
							<img src="../../../src/assets/img/icon609.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="body">
				<div class="body-content">
					<span>概况：</span>{{detail.person_intro}}
				</div>
				<div class="body-phone">
					<span>家属电话：</span>{{detail.family_phone}}
				</div>
			</div>
			<div class="footer">
				<div class="footer-img">
					<img src="../../../src/assets/img/gzgzh.jpg" alt="">
				</div>
				<div class="footer-content">
					<div class="footer-content-phone">
						<span>义警电话：</span>{{detail.vigilante_phone}}
					</div>
					<div class="footer-content-bottom">
						<div class="content-bottom-text">长按扫码进入平台</div>
						<div class="content-bottom-icon">
							<img src="../../../src/assets/img/icon606.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="botton">
			<div class="botton-text" @click="convertDivToImage">分享图片</div>
			<!-- 请先保存相册，然后分享朋友圈。 -->
		</div>
		<van-popup v-model="show">
			<div class="fotr">
				<img :src="fileId" alt="">
			</div>
			<div class="popup-footer" @click="onDownload">
				<div class="popup-footer-icon">
					<img src="@/assets/img/u_icon124.png" alt="">
				</div>
				<div class="popup-footer-text">保存图片</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import html2canvas from 'html2canvas'
	import Notice from '@/api/notice';
	import LoadingPage from '@/components/LoadingPage';
	import Config from '@/vendor/config';
	import MomentVendor from '@/vendor/moment';
	import VantVendor from '@/vendor/vant';
	import axios from 'axios';

	export default {
		name: 'NoticeDetail',
		data() {
			return {
				detail: '',
				id: '',
				title: '',
				fileId: "",
				loadingPage: false,
				show: false,
				posterimgUrl: '',
				imgUrl: '',
			}
		},
		created() {
			this.id = this.$route.params.detailId;
			this.getDetail()
		},
		methods: {
			//获取截图
			getBase64(fileId) {
				this.fileId = fileId
				this.show = true
			},
			//页面生成图片(基础版)
			htmlTocanvas() {
				var canvas2 = document.createElement("canvas");
				let _canvas = document.getElementById("NoticeDetail");
				var w = parseInt(window.getComputedStyle(_canvas).width);
				var h = parseInt(window.getComputedStyle(_canvas).height);
				canvas2.width = w * 3;
				canvas2.height = h * 3;
				canvas2.style.width = w + "px";
				canvas2.style.height = h + "px";
				canvas2.style.objectFit = 'cover'
				var context = canvas2.getContext("2d");

				html2canvas(document.getElementById("NoticeDetail"), {
					canvas: canvas2
				}).then(canvas => {
					this.getBase64(canvas.toDataURL("image/png"));
					VantVendor.Toast.clear();
				});
			},
			getDetail() {
				Notice.detail({
						people_search_id: this.id
					})
					.then(result => {
						this.detail = result.data
						this.loadingPage = false
						if (this.detail.district_name == '上饶市') {
							this.title = '上饶市义警'
						} else {
							this.title = this.detail.district_name.replace('区', '义警')
						}

						let time = MomentVendor(this.detail.lose_time).format('YYYY年M月DD日')

						let seachTitle =
							`寻人启事——${this.detail.person_name}，${this.detail.person_sex == 1 ? '男':'女'}，${time}在${this.detail.lose_address}走失。（${this.title}：${this.detail.vigilante_phone}）`

						wx.onMenuShareTimeline({
							title: seachTitle + '-' + document.title,
							link: window.Location.href,
							imgUrl: Config.shareLogoUrl,
							success: () => {
								this.isShowShare = false;
								Vant.Toast.success('分享成功');
							},
							error: () => {
								console.log(12);
							}
						});

						wx.onMenuShareAppMessage({
							title: seachTitle + '-' + document.title,
							link: window.Location.href,
							imgUrl: Config.shareLogoUrl,
							success: () => {
								this.isShowShare = false;
								Vant.Toast.success('分享成功');
							},
							error: () => {
								console.log(12);
							}
						});

						this.getImageInfo(this.detail.person_thumb)

					})
					.catch(error => {
						console.log(error);
					});
			},
			getImageInfo(path) {
				return new Promise((resolve, reject) => {
					axios.get(path, {
							responseType: 'blob'
						})
						.then(res => {
							const blob = res.data
							const url = URL.createObjectURL(blob)
							const img = new Image()
							img.src = url
							this.detail.person_thumb = url
							img.onload = () => {
								resolve(img)
							}
						})
						.catch(() => {
							console.log('加载图片失败')
							reject(new Error('加载图片失败'))
						})
				})
			},
			//转换图片，传入元素id
			convertDivToImage() {
				VantVendor.Toast.loading('请先保存图片到相册，然后分享朋友圈...');
				this.htmlTocanvas()
			},
			// 下载图片
			onDownload() {
				this.savePicture(this.fileId);
			},
			savePicture(Url) {
				var triggerEvent = "touchstart"; //指定下载方式
				var blob = new Blob([''], {
					type: 'application/octet-stream'
				}); //二进制大型对象blob
				var url = URL.createObjectURL(blob); //创建一个字符串路径空位
				var a = document.createElement('a'); //创建一个 a 标签
				a.href = Url;
				a.download = Url.replace(/(.*\/)*([^.]+.*)/ig, "$2").split("?")[0];
				var e = new MouseEvent('click', (true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null));
				a.dispatchEvent(e);
				URL.revokeObjectURL(url);
			}
			
		},
		components: {
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.NoticeDetail {
		width: 100%;
		min-height: 100vh;
		background: #082B8E;

		.NoticeDetail-content {
			width: 100%;
			height: 531px;
			background: url('../../assets/img/icon605.png');
			background-size: cover;
			padding: 123px 0 50px;
			box-sizing: border-box;

			.header {

				.header-top {
					margin-left: 38px;
					display: flex;
					align-items: center;

					.header-top-left {
						width: 83px;
						color: #FFF;
						font-size: 11px;
						font-weight: bold;
						text-align: center;
					}

					.header-top-right {
						width: 199px;
						text-align: center;
						color: #FFF;
						font-size: 11px;
						font-weight: bold;
					}


				}

				.header-content {
					display: flex;
					align-items: center;
					margin: 14px 35px 0;

					.header-content-left {
						width: 94px;
						height: 125px;
						min-width: 94px;

						img {
							width: 100%;
							height: 100%;
							// object-fit: cover !important;
						}
					}

					.header-content-right {
						flex: 1;
						margin-left: 12px;
						position: relative;

						.header-content-text {
							margin-top: 2px;
							color: #1C1D1D;
							font-size: 13px;
							font-weight: bold;
							line-height: 19px;

							span {
								color: #082B8F;
								font-size: 13px;
								font-weight: bold;
							}
						}

						.header-content-icon {
							position: absolute;
							top: 35px;
							right: 10px;
							width: 60px;
							height: 24px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}

							&.bottom-icon {
								position: absolute;
								top: 56px;
								right: 0;
							}
						}
					}
				}
			}

			.body {
				margin: 10px 35px 0;

				.body-content {
					border: 0.63px dashed #082B8E;
					padding: 6px;
					color: #1C1D1D;
					font-size: 13px;
					font-weight: bold;
					line-height: 19px;
					box-sizing: border-box;
					min-height: 124px;

					span {
						color: #082B8F;
						font-size: 13px;
						font-weight: bold;
					}
				}

				.body-phone {
					color: #1C1D1D;
					font-size: 13px;
					font-weight: bold;
					margin-top: 12px;

					span {
						color: #082B8F;
						font-size: 13px;
					}
				}
			}

			.footer {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 30px;

				.footer-img {
					width: 52px;
					height: 52px;
					margin-right: 16px;

					img {
						width: 52px;
						height: 52px;
						object-fit: cover;
					}
				}

				.footer-content {
					.footer-content-phone {
						display: flex;
						align-items: center;
						color: #FFF;
						font-size: 19px;
						letter-spacing: 1.9px;

						span {
							color: #FFF;
							font-size: 12px;
						}
					}

					.footer-content-bottom {
						margin-top: 8px;
						display: flex;
						align-items: center;

						.content-bottom-text {
							color: #FFF;
							font-size: 12px;
						}

						.content-bottom-icon {
							width: 16px;
							height: 16px;
							margin-left: 4px;

							img {
								width: 16px;
								height: 16px;
								object-fit: cover;
							}
						}
					}
				}
			}
		}

		/deep/.van-popup {
			background-color: initial;
		}

		.fotr {
			width: 300px;
			height: auto;

			img {
				width: 300px;
				height: auto;
				object-fit: cover !important;
			}
		}

		.popup-footer {
			
			.popup-footer-icon{
				width: 24px;
				height: 24px;
				margin: 0 auto;
				
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.popup-footer-text{
				text-align: center;
				color: #FFF;
				font-size: 12px;
				line-height: 18px;
			}
		}

		.botton {
			width: 100%;
			position: fixed;
			left: 0;
			bottom: 20px;
			padding: 0 70px;
			box-sizing: border-box;

			.botton-text {
				padding: 8px 0;
				text-align: center;
				color: #F7F7F9;
				font-size: 16px;
				border-radius: 4px;
				border: 1px solid #3E5CB0;
				background: #0E38AD;
				box-sizing: border-box;
				line-height: 24px;
			}
		}
	}
</style>